import {
  ArrowBackIosNew,
  CodeOutlined,
  HistoryOutlined,
  HomeOutlined,
  OpenInNew,
  PaymentsRounded,
  SettingsSuggestOutlined,
  SpeedOutlined,
  ViewCozyOutlined,
} from '@mui/icons-material'
import { SvgIcon } from '@mui/material'
import { generatePath, Params, To } from 'react-router-dom'

import { Product, productTypeValues } from 'features/product/types/product'
import GoogleAdManagerIcon from 'images/icons/google-ad-manager.svg?react'
import KnowledgeBaseIcon from 'images/icons/knowledge-base.svg?react'
import PortalIcon from 'images/icons/portal.svg?react'
import { theme } from 'providers/material-ui'
import { ROUTES } from 'routes'
import { SidebarItem } from '../types'
import { URL } from './constants'
import { performanceProductItems } from './performanceProductItems'
import { ConfigGeneralProps } from './types'

export const configGeneral = ({
  ability,
  navigate,
  networkCode,
  t,
  tProduct,
  updateCurrentWorkspace,
  workspaceId,
}: ConfigGeneralProps): SidebarItem[] => {
  const withWorkspace = (path: string, params?: Params): To =>
    generatePath(path, { workspaceId, ...params })

  const BACK_TO_WORKSPACES: SidebarItem = {
    color: theme.palette.cream,
    icon: <ArrowBackIosNew />,
    marginBottom: true,
    onClick: async () => {
      const status = await updateCurrentWorkspace(undefined)
      if (status === 'failure') return

      navigate(ROUTES.WORKSPACE.LIST)
    },
    title: t('sidebar.employee.backToWorkspaces'),
    to: ROUTES.WORKSPACE.LIST,
  }

  const INVENTORY_GROUP: SidebarItem = {
    group: 'inventory',
    icon: <ViewCozyOutlined />,
    subItems: [
      {
        title: t('sidebar.general.inventory.domains'),
        to: withWorkspace(ROUTES.INVENTORY.DOMAIN.LIST),
      },
      {
        title: t('sidebar.general.inventory.pages'),
        to: withWorkspace(ROUTES.INVENTORY.PAGE.LIST),
      },
      {
        title: t('sidebar.general.inventory.adUnits'),
        to: withWorkspace(ROUTES.INVENTORY.AD_UNIT.LIST),
      },
      {
        title: t('sidebar.general.inventory.viewports'),
        to: withWorkspace(ROUTES.INVENTORY.VIEWPORT),
      },
    ],
    title: t('sidebar.general.inventory.title'),
  }

  const PERFORMANCE_GROUP: SidebarItem = {
    group: 'performance',
    icon: <SpeedOutlined />,
    subItems: [
      {
        group: 'performance-prebidStack',
        hidden: ability.cannot('show', 'Product-prebidStack'),
        subItems: [
          {
            shifted: true,
            title: t('sidebar.general.performance.prebidStack.analytics'),
            to: withWorkspace(ROUTES.PERFORMANCE.PREBID_STACK.ANALYTICS, { tab: 'total' }),
          },
          {
            shifted: true,
            title: t('sidebar.general.performance.prebidStack.ybBidders'),
            to: withWorkspace(ROUTES.PERFORMANCE.ROOT + '/prebid-stack'),
          },
        ],
        title: t('sidebar.general.performance.prebidStack.title'),
      },
      ...performanceProductItems({
        ability,
        products: productTypeValues.filter((p: Product) => p !== 'prebidStack'),
        t: tProduct,
        workspaceId,
      }),
    ],
    title: t('sidebar.general.performance.title'),
  }

  const GLOBAL_SETUP_GROUP: SidebarItem = {
    group: 'globalSetup',
    icon: <SettingsSuggestOutlined />,
    subItems: [
      {
        group: 'globalSetup-prebid',
        hidden: ability.cannot('show', 'Product-prebidStack'),
        subItems: [
          {
            shifted: true,
            title: t('sidebar.general.globalSetup.prebid.defaults'),
            to: withWorkspace(ROUTES.GLOBAL_SETUP.PREBID.DEFAULTS.ROOT),
          },
          {
            shifted: true,
            title: t('sidebar.general.globalSetup.prebid.bidders'),
            to: withWorkspace(ROUTES.GLOBAL_SETUP.PREBID.BIDDERS.ROOT),
          },
          {
            hidden: !ability.can('access', 'GlobalSetupPage-prebid-modules'),
            shifted: true,
            title: t('sidebar.general.globalSetup.prebid.modules'),
            to: withWorkspace(ROUTES.GLOBAL_SETUP.PREBID.MODULES.ROOT, { tab: 'userId' }),
          },
          {
            shifted: true,
            title: t('sidebar.general.globalSetup.prebid.protection'),
            to: withWorkspace(ROUTES.GLOBAL_SETUP.PREBID.PROTECTION.LIST),
          },
        ],
        title: t('sidebar.general.globalSetup.prebid.title'),
      },
      {
        hidden: !ability.can('access', 'GlobalSetupPage-supplyChain-list'),
        title: t('sidebar.general.globalSetup.schain'),
        to: withWorkspace(ROUTES.GLOBAL_SETUP.SCHAIN.LIST),
      },
      {
        hidden: !ability.can('access', 'GlobalSetupPage-googleAdManagerConnection'),
        title: t('sidebar.general.globalSetup.gamConnection'),
        to: withWorkspace(ROUTES.GLOBAL_SETUP.GAM_CONNECTION.ROOT),
      },
      {
        hidden: !ability.can('access', 'GlobalSetupPage-amazon'),
        title: t('sidebar.general.globalSetup.amazon'),
        to: withWorkspace(ROUTES.GLOBAL_SETUP.AMAZON.ROOT, { tab: 'general' }),
      },
      {
        title: t('sidebar.general.globalSetup.general'),
        to: withWorkspace(ROUTES.GLOBAL_SETUP.GENERAL.ROOT, { tab: 'workspace' }),
      },
    ],
    title: t('sidebar.general.globalSetup.title'),
  }

  const showFinanceItem: boolean = ability.can('access', 'FinancePage')

  return [
    ...(ability.can('access', 'WorkspacePage-list') ? [BACK_TO_WORKSPACES] : []),

    {
      icon: <HomeOutlined />,
      title: t('sidebar.general.home'),
      to: ROUTES.BASE.HOME,
    },
    INVENTORY_GROUP,
    PERFORMANCE_GROUP,
    GLOBAL_SETUP_GROUP,
    {
      icon: <HistoryOutlined />,
      title: t('sidebar.general.history'),
    },
    {
      gapAfter: !showFinanceItem,
      icon: <CodeOutlined />,
      title: t('sidebar.general.downloadTags'),
      to: withWorkspace(ROUTES.TAG),
    },
    {
      gapAfter: true,
      hidden: !showFinanceItem,
      icon: <PaymentsRounded />,
      title: t('sidebar.general.finance'),
      to: withWorkspace(ROUTES.FINANCE),
    },
    {
      endAdornment: <OpenInNew fontSize='inherit' />,
      icon: (
        <SvgIcon
          component={GoogleAdManagerIcon}
          inheritViewBox
        />
      ),
      newTab: true,
      title: t('sidebar.external.googleadManagerAccount'),
      to: `https://admanager.google.com/${networkCode}#home`,
    },
    {
      endAdornment: <OpenInNew fontSize='inherit' />,
      icon: (
        <SvgIcon
          component={PortalIcon}
          inheritViewBox
        />
      ),
      newTab: true,
      title: t('sidebar.external.portal'),
      to: URL.PORTAL,
    },
    {
      endAdornment: <OpenInNew fontSize='inherit' />,
      icon: (
        <SvgIcon
          component={KnowledgeBaseIcon}
          inheritViewBox
        />
      ),
      newTab: true,
      title: t('sidebar.external.knowledgeBase'),
      to: URL.KNOWLEDGE_BASE,
    },
  ]
}
