import { Stack, StackProps } from '@mui/material'

export const StackForm = (stackProps: StackProps): JSX.Element => (
  <Stack
    {...stackProps}
    alignItems='flex-start'
    direction='row'
    flexWrap='wrap'
    gap={2}
    pt={1}
  />
)
