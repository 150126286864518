import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { PageHeader } from 'components/Header'
import Loading from 'components/Loading'
import { GamConnectionOrder } from 'features/workspace/types/gamConnectionOrder'
import { useGetConnection } from './hooks/useGetConnection'
import { ConnectionStatusSection } from './sections/ConnectionStatusSection'
import { GoogleAdManagerIdSection } from './sections/GoogleAdManagerIdSection'

export const GoogleAdManagerConnectionPage = (): JSX.Element => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'googleAdManagerConnection',
  })

  const [connection, setConnection] = useState<GamConnectionOrder | undefined>(undefined)
  const { loading: loadingCurrentConnection } = useGetConnection(setConnection)

  return (
    <>
      <PageHeader title={t('header')} />
      <GoogleAdManagerIdSection />
      {loadingCurrentConnection ? (
        <Loading />
      ) : (
        <ConnectionStatusSection
          connection={connection}
          setConnection={setConnection}
        />
      )}
    </>
  )
}
