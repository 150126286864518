import { useTranslation } from 'react-i18next'

import { Tab } from 'components/Tabs'
import { WorkspaceFormContainer } from '../forms/WorkspaceForm'
import { GeneralPageTab } from '../types'
import { WorkspaceUserList } from '../WorkspaceUserList'

export const useTabs = (): Tab<GeneralPageTab>[] => {
  const { t } = useTranslation('features/globalSetup')

  return [
    {
      element: 'workspace',
      label: t('general.tab.workspace'),
      page: <WorkspaceFormContainer />,
    },
    {
      element: 'user-management',
      label: t('general.tab.userManagement'),
      page: <WorkspaceUserList />,
    },
  ]
}
