import { TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { SectionHeader } from 'components/Header'
import Paper from 'components/Paper'
import { useAuthUser } from 'features/user'

export const GoogleAdManagerIdSection = (): JSX.Element => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'googleAdManagerConnection',
  })

  const {
    user: { currentWorkspace },
  } = useAuthUser()

  if (!currentWorkspace) {
    throw Error('Workspace should be accessible')
  }

  const { networkCode } = currentWorkspace

  return (
    <Paper>
      <SectionHeader
        bottomSpacing
        subtitle={t('networkCode.header.description')}
        title={t('networkCode.header.title')}
      />

      <TextField
        disabled
        id='networkCode'
        label={t('networkCode.inputLabel')}
        value={networkCode}
      />
    </Paper>
  )
}
