import { SvgIcon, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { FlexBox } from 'components/Box'
import Tooltip from 'components/Tooltip'
import { GamConnectionOrder } from 'features/workspace/types/gamConnectionOrder'
import { CONTAINER_SPACING } from 'providers/material-ui/theme/constants'
import { DATE_FORMAT, formatDate } from 'utils/date'
import { ErrorBox } from './ErrorBox'
import { useConfig } from './useConfig'

export const ConnectionStatus = ({
  errorMessage,
  status,
  updatedAt,
}: GamConnectionOrder): JSX.Element => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'googleAdManagerConnection.connectionStatus',
  })

  const { color, icon, tooltip } = useConfig(status)
  const isConnectionFailed = status === 'failed'

  const Status = (): JSX.Element => (
    <>
      {icon && (
        <SvgIcon
          component={icon}
          inheritViewBox
        />
      )}

      <Typography>
        {t(`status.${status}`, {
          updatedAt: formatDate(updatedAt, DATE_FORMAT.WITH_TIME),
        })}
      </Typography>
    </>
  )

  return (
    <>
      <FlexBox
        axis='x'
        data-cy='connection-status'
        sx={{
          color,
          gap: 1,
          mb: CONTAINER_SPACING,
        }}
      >
        <Status />
        {tooltip && <Tooltip {...tooltip} />}
      </FlexBox>

      {isConnectionFailed && (
        <ErrorBox
          errorMessage={errorMessage}
          sx={{ mb: CONTAINER_SPACING }}
        />
      )}
    </>
  )
}
