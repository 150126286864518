import { useFormContext } from 'react-hook-form'

import { NumberField, TextField } from 'components/Form'
import { TextFieldProps } from 'components/Form/TextField'
import { Schema } from 'features/globalSetup/pages/Prebid/Bidders/EditBidderPage/pages/BidParamsAndConnection/form/schema'
import { AdUnitParameter } from 'features/globalSetup/types/adUnitBidder'
import { useAbility } from 'providers/casl'
import { AdUnitBidderParamFieldPath, Props } from './types'

export const AdUnitParamInputCell = ({
  cellContext: {
    cell: { getValue },
    row: {
      original: { id: adUnitBidderId },
    },
  },
  kind,
}: Props): JSX.Element | undefined => {
  const ability = useAbility()
  const { control, getValues, register } = useFormContext<Schema>()

  const { name, type, value } = getValue() as AdUnitParameter

  const fieldPath: AdUnitBidderParamFieldPath = `adUnitBidders.${adUnitBidderId}.${kind}Params.${name}`

  if (getValues(fieldPath) === undefined) {
    register(fieldPath, { value })
  }

  const inputProps: TextFieldProps<Schema> = {
    control,
    disabled: ability.cannot('update', 'GlobalSetupFeature-bidder'),
    key: `${kind}-${name}`,
    name: fieldPath,
    useTooltipForErrors: true,
  }

  return type === 'string' ? (
    <TextField
      {...inputProps}
      width='sm'
    />
  ) : (
    <NumberField
      {...inputProps}
      type={type}
      width='sm'
    />
  )
}
